"use strict";

var globeGif = require('./lib/Styles/globe.gif');

require('./lib/Styles/loader.css');

function loadMainScript() {
  // load the main chunk
  return new Promise(function (resolve, reject) {
    require.ensure(['./index'], function (require) {
      resolve(require('./index'));
    }, function (error) {
      reject(error);
    }, 'index');
  });
}

function createLoader() {
  var loaderDiv = document.createElement('div');
  loaderDiv.classList.add("loader-ui");
  var loaderGif = document.createElement('img');
  loaderGif.src = globeGif;
  var loaderLeft = document.createElement('div');
  loaderLeft.classList.add("loader-ui-left");
  var loaderGrabber = document.createElement('div');
  loaderGrabber.classList.add('loader-ui-grabber');
  var loaderRight = document.createElement('div');
  loaderRight.classList.add("loader-ui-right");
  loaderRight.append(loaderGif);
  loaderDiv.append(loaderLeft);
  loaderDiv.append(loaderRight);
  loaderDiv.append(loaderGrabber);
  loaderDiv.style.backgroundColor = '#383F4D';
  document.body.appendChild(loaderDiv);
  loadMainScript()["catch"](function () {// Ignore errors and try to show the map anyway
  }).then(function () {
    loaderDiv.classList.add('loader-ui-hide');
    setTimeout(function () {
      document.body.removeChild(loaderDiv);
    }, 2000);
  });
}

createLoader();